import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "./main/ScrollToTop";

import ChangeCountryCurrency from "../components/home/more/ChangeCountryCurrency";
import ContactUs from "../components/home/more/ContactUs";
import DeliveryTime from "../components/home/more/DeliveryTime";
import DutiesTaxes from "../components/home/more/DutiesTaxes";
import InternationalTransactionFees from "../components/home/more/InternationalTransactionFees";
import PaymentMethods from "../components/home/more/PaymentMethods";
import PriceCurrencies from "../components/home/more/PriceCurrencies";
import Refund from "../components/home/more/Refund";
import Returns from "../components/home/more/Returns";
import ShippingCost from "../components/home/more/ShippingCost";
import ShippingMethods from "../components/home/more/ShippingMethods";
import Tracking from "../components/home/more/Tracking";

import Authentification from "../components/home/worldOfWonley/Authentification";
import Club from "../components/home/worldOfWonley/Club";
import Episodes from "../components/home/worldOfWonley/Episodes";
import FollowUs from "../components/home/worldOfWonley/FollowUs";
import Meaning from "../components/home/worldOfWonley/Meaning";
import Membership from "../components/home/worldOfWonley/Membership";
import Mission from "../components/home/worldOfWonley/Mission";
import OneOfOneLuxury from "../components/home/worldOfWonley/OneOfOneLuxury";
import Philanthropy from "../components/home/worldOfWonley/Philanthropy";
import Products from "../components/home/worldOfWonley/Products";
import PurchasingProduct from "../components/home/worldOfWonley/PurchasingProduct";
import Support from "../components/home/worldOfWonley/Support";
import Fundraiser from "./home/worldOfWonley/Fundraiser";
import Gifts from "./home/worldOfWonley/Gifts";

import Body from "../components/home/worldOfWonley/Episodes/SubEpisodes/Body";
import Joy from "../components/home/worldOfWonley/Episodes/SubEpisodes/Joy";
import Knowledge from "../components/home/worldOfWonley/Episodes/SubEpisodes/Knowledge";
import Mind from "../components/home/worldOfWonley/Episodes/SubEpisodes/Mind";
import ViewEpisodes from "../components/home/worldOfWonley/Episodes/ViewEpisodes";

import Measure from "./home/products/Measure";
import ProductDetails from "./home/products/ProductDetails";
import ProductDimensions from "./home/products/ProductDimensions";
import ProductPrice from "./home/products/ProductPrice";
import ProductQuestions from "./home/products/ProductQuestions";
import ProductRelated from "./home/products/ProductRelated";
import ProductSizeGuide from "./home/products/ProductSizeGuide";
import ProductVideo from "./home/products/ProductVideo";
import ShopProduct from "./home/products/ShopProduct";
import SizePicker from "./home/products/SizePicker";
import Material from "./home/products/product-details/Material";
import ProductInfo from "./home/products/product-details/ProductInfo";
import ProductRelatedVideo from "./home/products/product-details/ProductRelatedVideo";
import Purchase from "./home/products/product-details/Purchase";
import SuccessPaid from "./home/products/product-details/SuccessPaid";

// Routes for content changes !!!
import PrivacyPolicy from "./home/more/PrivacyPolicy";
import TermsAndConditions from "./home/more/TermsAndConditions";

// admin
import AdEditSizeGuide from "./admin/AdEditSizeGuide";
import AdPicture from "./admin/AdPicture";
import AdProductImage from "./admin/AdProductImage";
import AdProduct from "./admin/AdProducts";
import AdSizeGuideImage from "./admin/AdSizeGuideImage";
import AdType from "./admin/AdType";
import AdTypeVideo from "./admin/AdTypeVideo";
import AdVideo from "./admin/AdVideo";
import AdminHome from "./admin/AdminHome";
import EditSize from "./admin/EditSize";
import OrderDetails from "./admin/OrderDetails";
import Orders from "./admin/Orders";
import Shippings from "./admin/Shippings";
import ViewSizeGuides from "./admin/SizeGuides";
import Sizes from "./admin/Sizes";
import Types from "./admin/Types";
import ViewDimensions from "./admin/ViewDimensions";
import ViewProduct from "./admin/ViewProduct";
import ViewProducts from "./admin/ViewProducts";
import ViewProductsAuth from "./admin/ViewProductsAuth";

// size finder
import ViewMembers from "./admin/ViewMembers";
import AdOption from "./admin/sizeFinder/AdOption";
import AdOptionImage from "./admin/sizeFinder/AdOptionImage";
import AdStep from "./admin/sizeFinder/AdStep";
import AnswerDetails from "./admin/sizeFinder/AnswerDetails";
import ViewAnswers from "./admin/sizeFinder/ViewAnswers";
import ViewOptions from "./admin/sizeFinder/ViewOptions";
import ViewSizeFinders from "./admin/sizeFinder/ViewSizeFinders";
import ViewSteps from "./admin/sizeFinder/ViewSteps";

import TwoBoutiques from "./home/Boutique/TwoBoutiques";
import Taxes from "./home/more/Taxes";
import MeaningOfDesign from "./home/products/MeaningOfDesign";
import Lyrics from "./home/worldOfWonley/Mission/Lyrics";
import Music from "./home/worldOfWonley/Mission/Music";
import VideoPage from "./home/worldOfWonley/Mission/VideoPage";
import Written from "./home/worldOfWonley/Mission/Written";

// home page
import Admin from "./admin/Admin";
import Accessibility from "./home/Accessibility";
import Authentication from "./home/Authentication";
import ShowingCard from "./home/Authentication/ShowingCard";
import Transfer from "./home/Authentication/Transfer";
import TransferFinish from "./home/Authentication/TransferFinish";
import Boutique from "./home/Boutique";
import ForgotPwd from "./home/ForgotPwd";
import Home from "./home/Home";
import Men from "./home/Men";
import More from "./home/More";
import ProductCare from "./home/ProductCare";
import HomeProducts from "./home/Products";
import Women from "./home/Women";
import WorldOfWonley from "./home/WorldOfWonwonleywon";

// All videos - static videos pages
import AllVideos from "./home/AllVideos";
import Collection1BagVideo from "./home/AllVideos/collection1BagVideo";
import MeaningOfWonwonleywonName from "./home/AllVideos/meaningOfWonwonleywonName";
import MissionVideo from "./home/AllVideos/missionVideo";
import MusicVideo from "./home/AllVideos/musicVideo";
import oneOfOneLuxuryVideo from "./home/AllVideos/oneOfOneLuxuryVideo";
import CollectionsVideo from "./home/CollectionsVideo";

import AboutUs from "./home/AllVideos/aboutUs";
import Collection10Video from "./home/AllVideos/collection10Video";
import Collection1112Video from "./home/AllVideos/collection1112Video";
import Collection13Video from "./home/AllVideos/collection13Video";
import Collection2Video from "./home/AllVideos/collection2Video";
import Collection3Video from "./home/AllVideos/collection3Video";
import Collection4Video from "./home/AllVideos/collection4Video";
import Collection5Video from "./home/AllVideos/collection5Video";
import Collection6Video from './home/AllVideos/collection6Video';
import Collection7Video from "./home/AllVideos/collection7Video";
import Collection8Video from "./home/AllVideos/collection8Video";
import Collection9Video from "./home/AllVideos/collection9Video";
import FashionShow from "./home/AllVideos/fashionShow";
import HowToChangeColors from "./home/AllVideos/howToChangeColors";
import HowToShop from "./home/AllVideos/howToShop";
import SingleProductsList from "./home/products/SingleProductsList";
import Collection14Video from "./home/AllVideos/collection14Video";
import Collection15Video from "./home/AllVideos/collection15Video";

class CreateRoutes extends Component {
  render() {
    const user = sessionStorage.getItem("user");
    return (
      <BrowserRouter>
        <Switch>
          {/* home page */}
          <ScrollToTop>
            <Route exact path="/home" component={Home} />
            <Route exact path="/world" component={WorldOfWonley} />
            <Route exact path="/products" component={HomeProducts} />
            <Route path="/women" component={Women} />
            <Route path="/men" component={Men} />
            <Route path="/more" exact component={More} />
            <Route path="/boutique" exact component={Boutique} />
            <Route path="/authenticity" exact component={Authentication} />
            <Route path="/authenticity/showingcard" component={ShowingCard} />
            <Route path="/authenticity/transfer" component={Transfer} />
            <Route path="/authenticity/finish" component={TransferFinish} />
            <Route path="/admin" exact component={Admin} />
            <Route path="/care/:id" exact component={ProductCare} />
            <Route path="/forgot-pwd" exact component={ForgotPwd} />
            <Route path="/accessibility" exact component={Accessibility} />

            {/* more... page */}
            <Route path="/more/contact_us" component={ContactUs} />
            <Route
              path="/more/changecountrycurrency"
              component={ChangeCountryCurrency}
            />
            <Route path="/more/shipping_methods" component={ShippingMethods} />
            <Route path="/more/shipping_cost" component={ShippingCost} />
            <Route path="/more/delivery_time" component={DeliveryTime} />
            <Route path="/more/payment_methods" component={PaymentMethods} />
            <Route path="/more/duties" component={DutiesTaxes} />
            <Route path="/more/prices_currencies" component={PriceCurrencies} />
            <Route
              path="/more/int_transaction_fees"
              component={InternationalTransactionFees}
            />
            <Route path="/more/returns" component={Returns} />
            <Route path="/more/refund" component={Refund} />
            <Route path="/more/tracking" component={Tracking} />
            <Route path="/more/taxes" component={Taxes} />
            {/* end */}

            {/* world of wonwonleywon page */}
            <Route
              path="/world/authentification"
              component={Authentification}
            />
            <Route path="/world/club" component={Club} />
            <Route path="/world/episodes" exact component={Episodes} />
            <Route path="/world/followus" component={FollowUs} />
            <Route path="/world/meaning" component={Meaning} />
            <Route path="/world/membership" component={Membership} />
            <Route path="/world/mission" exact component={Mission} />
            <Route path="/world/oneofoneluxury" component={OneOfOneLuxury} />
            <Route path="/world/philanthropy" component={Philanthropy} />
            <Route path="/world/products" component={Products} />
            <Route
              path="/world/purchasingproduct"
              component={PurchasingProduct}
            />
            <Route path="/world/support" component={Support} />
            <Route path="/world/gifts" component={Gifts} />
            <Route path="/world/fundraiser" component={Fundraiser} />
            {/* end */}

            <Route path="/world/mission/music" component={Music} />
            <Route path="/world/mission/video" component={VideoPage} />
            <Route path="/world/mission/written" component={Written} />
            <Route path="/world/mission/lyrics" component={Lyrics} />
            <Route
              path="/collection-video/:id"
              exact
              component={CollectionsVideo}
            />
            {/* all videos page */}
            <Route path="/allvideos" exact component={AllVideos} />
            <Route
              path="/allvideos/one-of-one"
              exact
              component={oneOfOneLuxuryVideo}
            />
            <Route
              path="/allvideos/mission-video"
              exact
              component={MissionVideo}
            />
            <Route
              path="/allvideos/meaning-video"
              exact
              component={MeaningOfWonwonleywonName}
            />
            <Route path="/allvideos/music-video" exact component={MusicVideo} />
            <Route
              path="/allvideos/colection-bag"
              exact
              component={Collection1BagVideo}
            />
            <Route
              path="/allvideos/collection-2"
              exact
              component={Collection2Video}
            />
            <Route
              path="/allvideos/collection-3"
              exact
              component={Collection3Video}
            />
            <Route
              path="/allvideos/collection-4"
              exact
              component={Collection4Video}
            />
            <Route
              path="/allvideos/collection-5"
              exact
              component={Collection5Video}
            />
            <Route
              path="/allvideos/collection-6"
              exact
              component={Collection6Video}
            />
            <Route
              path="/allvideos/collection-7"
              exact
              component={Collection7Video}
            />
            <Route
              path="/allvideos/collection-8"
              exact
              component={Collection8Video}
            />
            <Route
              path="/allvideos/collection-9"
              exact
              component={Collection9Video}
            />
            <Route
              path="/allvideos/collection-10"
              exact
              component={Collection10Video}
            />
            <Route
              path="/allvideos/collection-1112"
              exact
              component={Collection1112Video}
            />
            <Route
              path="/allvideos/collection-13"
              exact
              component={Collection13Video}
            />
            <Route
              path="/allvideos/collection-14"
              exact
              component={Collection14Video}
            />
            <Route
              path="/allvideos/collection-15"
              exact
              component={Collection15Video}
            />
            <Route
              path="/allvideos/fashion-show"
              exact
              component={FashionShow}
            />

            <Route path="/allvideos/about-us" exact component={AboutUs} />
            <Route path="/allvideos/how-to-shop" exact component={HowToShop} />
            <Route
              path="/allvideos/how-to-colors"
              exact
              component={HowToChangeColors}
            />
            {/* episodes page */}
            <Route
              path="/world/episodes/viewepisodes/"
              exact
              component={ViewEpisodes}
            />
            <Route
              path="/world/episodes/viewepisodes/body/"
              exact
              component={Body}
            />
            <Route
              path="/world/episodes/viewepisodes/joy/"
              exact
              component={Joy}
            />
            <Route
              path="/world/episodes/viewepisodes/knowledge/"
              exact
              component={Knowledge}
            />
            <Route
              path="/world/episodes/viewepisodes/mind/"
              exact
              component={Mind}
            />
            {/* end */}
            <Route path="/boutique/twoboutiques" component={TwoBoutiques} />

            {/* all videos page */}

            {/* end */}

            {/* product page */}
            <Route path="/products/productrelated" component={ProductRelated} />
            <Route
              path="/products/meaning-of-design"
              component={MeaningOfDesign}
            />
            <Route path="/products/productvideo" component={ProductVideo} />
            <Route
              path="/products/productsizeguide"
              component={ProductSizeGuide}
            />
            <Route path="/products/productprice" component={ProductPrice} />
            <Route path="/products/shopproduct" component={ShopProduct} />
            <Route path="/products/productdetails" component={ProductDetails} />
            <Route path="/products/material" component={Material} />
            <Route path="/products/productinfo" component={ProductInfo} />
            <Route path="/products/purchase" component={Purchase} />
            <Route path="/products/success" component={SuccessPaid} />
            <Route path="/products/sizepicker" component={SizePicker} />
            <Route path="/products/questions" component={ProductQuestions} />
            <Route path="/products/measure" component={Measure} />
            <Route path="/products/singleproducts" component={SingleProductsList} />
            <Route
              path="/products/videoofproduct"
              component={ProductRelatedVideo}
            />
            <Route
              path="/products/productdimensions"
              component={ProductDimensions}
            />

            <Route path="/more/privacy-policy" component={PrivacyPolicy} />
            <Route
              path="/more/terms-and-conditions"
              component={TermsAndConditions}
            />

            {/* end */}
            {(this.props.username && this.props.username) !== "" ||
            (user && user !== "") ? (
              <>
                <Route path="/adproduct" component={AdProduct} />
                <Route path="/admin-home" component={AdminHome} />
                <Route path="/view-types" component={Types} />
                <Route path="/adtype" component={AdType} />
                <Route path="/viewproducts" component={ViewProducts} />
                <Route path="/adpicture/:id" component={AdPicture} />
                <Route path="/advideo/:id" component={AdVideo} />
                <Route path="/viewdetails/:id" component={ViewProduct} />
                <Route path="/view-sizes" component={Sizes} />
                <Route path="/edit-size" component={EditSize} />
                <Route path="/orders" component={Orders} />
                <Route path="/view-order/:id" component={OrderDetails} />
                <Route path="/ad-type-video" component={AdTypeVideo} />
                <Route path="/view-size-guides" component={ViewSizeGuides} />
                <Route path="/edit-size-guide" component={AdEditSizeGuide} />
                <Route
                  path="/add-size-guide-img"
                  component={AdSizeGuideImage}
                />
                <Route path="/add-option-img" component={AdOptionImage} />
                <Route path="/view-size-finders" component={ViewSizeFinders} />
                <Route path="/view-steps" component={ViewSteps} />
                <Route path="/view-options" component={ViewOptions} />
                <Route path="/edit-step" component={AdStep} />
                <Route path="/edit-option" component={AdOption} />
                <Route path="/view-answers" component={ViewAnswers} />
                <Route path="/view-answer" component={AnswerDetails} />
                <Route path="/view-members" component={ViewMembers} />
                <Route
                  path="/view-products-auth"
                  component={ViewProductsAuth}
                />
                <Route path="/ad-product-image" component={AdProductImage} />
                <Route path="/view-dimensions/:id" component={ViewDimensions} />
                <Route path="/ad-shippings/:tid" component={Shippings} />
              </>
            ) : null}
          </ScrollToTop>
        </Switch>
      </BrowserRouter>
    );
  }
}
const mapStoreToProps = (state) => {
  return {
    username: state.user,
  };
};

export default connect(mapStoreToProps)(CreateRoutes);
