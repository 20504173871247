import {
  CardElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import axios from "../../../../axios-backend";
import CheckoutLines from "../../../main/CheckoutLines";
import Line from "../../../main/Line";
import RadioButton from "../../../main/RadioButton";
import Spinner from "../../../main/Spinner";
import Text from "../../../main/Text";
import PayPalCheckoutButton from "./PayPalCheckout";
import styles from "./css/CheckoutForm.module.css";

import AmericanStates from "../../../main/AmericanStates";
import CanadaProvinces from "../../../main/CanadaProvinces";
import Countries from "../../../main/Countries";
import PolicyLine from "../../../main/PolicyLine";
import PrivacyPolicy from "../../../main/PrivacyPolicy";
import TermsAndConditions from "../../../main/TermsAndConditions";
import { CardCvC, CardNumber, CardSection } from "./CardSection";

export default function CheckoutForm(props) {
  // const [orderId, setOrderId] = useState("");
  // const [secret, setSecret] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [cardName, setCardName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [unit, setUnit] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("-");
  const [countryState, setCountryState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [nameBilling, setNameBilling] = useState("");
  const [phoneBilling, setPhoneBilling] = useState("");
  const [emailBilling, setEmailBilling] = useState("");
  const [addressBilling, setAddressBilling] = useState("");
  const [address1Billing, setAddress1Billing] = useState("");
  const [address2Billing, setAddress2Billing] = useState("");
  const [unitBilling, setUnitBilling] = useState("");
  const [cityBilling, setCityBilling] = useState("");
  const [countryBilling, setCountryBilling] = useState("-");
  const [countryStateBilling, setCountryStateBilling] = useState("");
  const [postalCodeBilling, setPostalCodeBilling] = useState("");
  const [price, setPrice] = useState("");
  const [initUsPrice, setInitUsPrice] = useState("");
  const [shippingCost, setShippingCost] = useState("0");
  const [pending, setPending] = useState(false);
  const [tax, setTax] = useState("0");
  const [paymentType, setPaymentType] = useState(1);
  const [hasBilling, setHasBilling] = useState(false);
  const [shippingArr, setShippingArr] = useState([]);
  const [taxArr, setTaxArr] = useState([]);
  const [productName, setProductName] = useState("");
  const [errormessage, setErrormessage] = useState("");
  const [showPolicy, setShowPolicy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [productdetails, setProductDetails] = useState({});

  const [discountCode, setDiscountCode] = useState("");
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);

  useEffect(() => {
    setTaxArr([
      { area: "Alberta", tax: "5" },
      {
        area: "British Columbia",
        tax: "12",
      },
      { area: "Manitoba", tax: "12" },
      {
        area: "New Brunswick",
        tax: "15",
      },
      {
        area: "Newfoundland and Labrador",
        tax: "15",
      },
      {
        area: "Northwest Territories",
        tax: "5",
      },
      { area: "Nova Scotia", tax: "15" },
      { area: "Nunavut", tax: "5" },
      { area: "Ontario", tax: "13" },
      {
        area: "Prince Edward Island",
        tax: "15",
      },
      { area: "Quebec", tax: "14.975" },
      {
        area: "Saskatchewan",
        tax: "11",
      },
      { area: "Yukon", tax: "5" },
      { area: "USA", tax: "5" },
      { area: "Other", tax: "5" },
    ]);
    axios
      .get("/price-with-shipping", {
        params: {
          id: props.productId,
          tid: props.typeId,
        },
      })
      .then((d) => {
        if (d?.data.status === 200) {
          let priceUs = parseFloat(d.data.priceUs);
          if (Number.isInteger(priceUs)) {
            priceUs = priceUs.toFixed(0); // Convert to string with no decimal places
          } else {
            priceUs = priceUs.toFixed(2); // Convert to string with two decimal places
          }
          setPrice(priceUs);
          setInitUsPrice(priceUs);
          setShippingArr(d.data.shippingArr);
          setProductName(d.data.productName);
        } else {
          alert("something wrong. try again later.");
        }
      });
    axios
      .get(`/products/${props.productId}`)
      .then((data) => {
        if (data.status === 200) {
          setProductDetails(data.data[0]);
        } else {
          setProductDetails([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const handleDiscount = async () => {
    if (discountApplied) {
      alert("discount code already applied.");
      return;
    }

    // check if discount code is valid from backend
    try {
      const response = await axios.get("type-discount", {
        params: {
          discountCode: discountCode,
          typeId: props.typeId,
        },
      });

      const { isCodeValid, discountAmount } = response.data;

      if (isCodeValid) {
        setInitUsPrice(
          (initUsPrice - (initUsPrice * discountAmount) / 100).toFixed(2)
        );
        setDiscountAmount(discountAmount);
        setDiscountApplied(true);
      } else {
        alert("enter a valid discount code.");
      }
    } catch (error) {
      alert("Error validating discount code. Please try again.");
    }
  };

  useEffect(() => {
    setPrice(initUsPrice);
    getTax(countryState, shippingCost);
  }, [initUsPrice]);

  // get tax
  const getTax = (value, shippingFee) => {
    //#region use static tax for now, if he wants to add taxes table later, we will add it for him
    // axios
    //   .get("taxes", {
    //     params: {
    //       province: value,
    //     },
    //   })
    //   .then((data) => {
    //     console.log(data)
    //     if (data?.data.status === 200) {
    //       const taxAmount = parseInt((initUsPrice * data.data.tax) / 100);
    //       setTax(taxAmount);
    //       setPrice(taxAmount +
    //         parseInt(initUsPrice)
    //         // parseInt(data.data[0].shippingCost)
    //       );
    //       // setShippingCost(data.data[0].shippingCost);
    //     } else {
    //       alert('something wrong');
    //     }
    //   });
    //#endregion
    for (let i = 0; i < taxArr.length; i++) {
      const element = taxArr[i];
      if (element.area === value) {
        let taxAmount = parseInt(initUsPrice * element.tax) / 100;
        if (Number.isInteger(taxAmount)) {
          taxAmount = taxAmount.toFixed(0); // Convert to string with no decimal places
        } else {
          taxAmount = taxAmount.toFixed(2); // Convert to string with two decimal places
        }
        setTax(taxAmount);

        let totalPrice =
          parseFloat(taxAmount) +
          parseFloat(initUsPrice) +
          parseFloat(shippingFee);
        if (Number.isInteger(totalPrice)) {
          totalPrice = totalPrice.toFixed(0); // Convert to string with no decimal places
        } else {
          totalPrice = totalPrice.toFixed(2); // Convert to string with two decimal places
        }
        setPrice(totalPrice);

        setShippingCost(shippingFee);
        break;
      }
    }
  };

  const getShippingFee = (countryId) => {
    if (countryId) {
      for (let i = 0; i < shippingArr.length; i++) {
        const ele = shippingArr[i];
        if (ele.countryId == countryId) {
          if (productdetails?.removeShipmentCharge) return 0;
          else return ele.price;
        }
      }
    }
  };

  const handleSubmit = () => {
    setPending(true);
    if (!stripe || !elements) {
      return;
    }
    axios
      .get("/secret", {
        params: {
          name,
          phone,
          email,
          address,
          address1,
          address2,
          country: country.split("-")[1],
          unit,
          city,
          cardName,
          postalCode,
          state: countryState,
          id: props.productId,
          nameBilling,
          phoneBilling,
          emailBilling,
          addressBilling,
          address1Billing,
          address2Billing,
          countryBilling: countryBilling.split("-")[1],
          unitBilling,
          cityBilling,
          postalCodeBilling,
          stateBilling: countryStateBilling,
          countryId: country.split("-")[0],
          hasBilling,
          discountCode,
          discountAmount,
        },
      })
      .then(async (data) => {
        if (data.data?.status === 406) {
          alert("sorry, this product is already sold");
          setPending(false);
        } else if (data.data?.status === 200) {
          const result = await stripe.confirmCardPayment(
            data.data.client_secret,
            {
              payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                  name,
                },
              },
            }
          );
          if (result.error) {
            setPending(false);
            alert(result.error.message);
          } else {
            if (result.paymentIntent.status === "succeeded") {
              axios
                .patch("/orders", {
                  checkoutCode: data.data.checkoutCode,
                  id: props.productId,
                })
                .then((d) => {
                  if (d.data?.status === 200) {
                    axios.post("/product-auth", {
                      email,
                      pid: props.productId,
                    });
                    props.setSuccess(true);
                  } else {
                    alert(
                      "something is wrong. please contact us at wonwonleywon.com"
                    );
                    setPending(false);
                  }
                })
                .catch((r) => {
                  setPending(false);
                  alert(r);
                });
            } else {
              setPending(false);
              alert(
                "something is wrong. please contact us at wonwonleywon.com"
              );
            }
          }
        } else {
          setPending(false);
          alert("something is wrong. please contact us at wonwonleywon.com");
        }
      })
      .catch((r) => {
        console.log(r);
        setPending(false);
        alert("something is wrong. please contact us at wonwonleywon.com");
      });
  };

  const validate = () => {
    if (country !== "-" && name && phone && email && address && city) {
      if (
        (country === "34-Canada" || country === "212-United States") &&
        !countryState
      ) {
        setErrormessage("please fill out all the fields");
        return false;
      }
      if (hasBilling) {
        if (
          !(
            countryBilling !== "-" &&
            nameBilling &&
            phoneBilling &&
            emailBilling &&
            addressBilling &&
            cityBilling
          )
        ) {
          setErrormessage("please fill out billing information");
          return false;
        }
        if (
          (countryBilling === "34-Canada" ||
            countryBilling === "212-United States") &&
          !countryStateBilling
        ) {
          setErrormessage("please fill out billing information");
          return false;
        }
        setErrormessage("");
        return true;
      }
      setErrormessage("");
      return true;
    } else {
      setErrormessage("please fill out all the fields");
      return false;
    }
  };

  const handleCreateOrder = (checkoutCode) => {
    axios
      .post("/orders", {
        name,
        phone,
        email,
        address,
        address1,
        address2,
        country: country.split("-")[1],
        unit,
        city,
        postalCode,
        cardName,
        state: countryState,
        id: props.productId,
        nameBilling,
        phoneBilling,
        emailBilling,
        addressBilling,
        address1Billing,
        address2Billing,
        countryBilling: countryBilling.split("-")[1],
        unitBilling,
        cityBilling,
        postalCodeBilling,
        stateBilling: countryStateBilling,
        countryId: country.split("-")[0],
        hasBilling,
        checkoutCode,
        amount: price,
        shippingFee: shippingCost,
        discountCode,
        discountAmount,
      })
      .then((data) => {
        if (data.data.status === 200) {
          axios
            .patch("/orders", {
              checkoutCode,
              id: props.productId,
            })
            .then((d) => {
              if (d.data?.status === 200) {
                axios.post("/product-auth", {
                  email,
                  pid: props.productId,
                });
                props.setSuccess(true);
              } else {
                alert("something wrong, please contact us!");
                setPending(false);
              }
            })
            .catch((r) => {
              setPending(false);
              alert(r);
            });
        } else {
          alert("something wrong, please contact us");
        }
      });
  };

  return (
    <>
      <Spinner show={pending} />
      <div className={styles.containerForm}>
        <div className={styles.fieldset}>
          <Line type="bottom-line">shipping information</Line>
          <CheckoutLines text="Country">
            <select
              className={styles.selectorForPhone}
              value={country}
              style={{ textTransform: "lowercase" }}
              onChange={(e) => {
                setCountryState("");
                const countryName = e.target.value;
                const shippingFee = getShippingFee(countryName.split("-")[0]);
                setCountry(countryName);
                setTax("0");
                const newPrice =
                  parseFloat(initUsPrice) + parseFloat(shippingFee);
                setPrice(
                  Number.isInteger(newPrice)
                    ? newPrice.toFixed(0)
                    : newPrice.toFixed(2)
                );
                setShippingCost(shippingFee);
                // if (countryName === "212-United States") {
                //   getTax("USA", shippingFee);
                // } else if (countryName === "34-Canada") {
                //   setPrice(parseInt(initUsPrice) + parseInt(shippingFee));
                //   setShippingCost(shippingFee);
                // } else {
                //   getTax("Other", shippingFee);
                // }
              }}
            >
              <Countries />
            </select>
          </CheckoutLines>
          <CheckoutLines text="Full Name">
            <input
              className={styles.inputText}
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </CheckoutLines>
          <CheckoutLines text="Phone Number">
            <input
              className={styles.inputText}
              type="text"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </CheckoutLines>
          <CheckoutLines text="Email address">
            <input
              className={styles.inputText}
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </CheckoutLines>
          <CheckoutLines text="address line 1">
            <input
              className={styles.inputText}
              type="text"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </CheckoutLines>
          <CheckoutLines text="address line 2 (optional)">
            <input
              className={styles.inputText}
              type="text"
              value={address1}
              onChange={(e) => {
                setAddress1(e.target.value);
              }}
            />
          </CheckoutLines>
          <CheckoutLines text="address line 3 (optional)">
            <input
              className={styles.inputText}
              type="text"
              value={address2}
              onChange={(e) => {
                setAddress2(e.target.value);
              }}
            />
          </CheckoutLines>
          <CheckoutLines text="apartment, suite, floor, unit (optional)">
            <input
              className={styles.inputText}
              type="text"
              value={unit}
              onChange={(e) => {
                setUnit(e.target.value);
              }}
            />
          </CheckoutLines>
          <CheckoutLines
            text="Province"
            style={{ display: country === "34-Canada" ? "block" : "none" }}
          >
            <select
              className={styles.selectorForPhone}
              value={countryState}
              onChange={(e) => {
                setCountryState(e.target.value);
                getTax(e.target.value, shippingCost);
              }}
              style={{ textTransform: "lowercase" }}
            >
              <CanadaProvinces />
            </select>
          </CheckoutLines>
          <CheckoutLines
            text="State"
            style={{
              display: country === "212-United States" ? "block" : "none",
            }}
          >
            <select
              className={styles.selectorForPhone}
              value={countryState}
              onChange={(e) => {
                setCountryState(e.target.value);
              }}
              style={{ textTransform: "lowercase" }}
            >
              <AmericanStates />
            </select>
          </CheckoutLines>
          <CheckoutLines text="city">
            <input
              className={styles.inputText}
              type="text"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
          </CheckoutLines>
          <CheckoutLines
            text={country === "212-United States" ? "zip code" : "Postal Code"}
          >
            <input
              className={styles.inputText}
              type="text"
              value={postalCode}
              onChange={(e) => {
                setPostalCode(e.target.value);
              }}
            />
          </CheckoutLines>

          {/* Discount */}
          <Line type="bottom-line"></Line>
          <CheckoutLines text="discount code">
            <input
              className={styles.inputText}
              type="text"
              value={discountCode}
              onChange={(e) => {
                setDiscountCode(e.target.value);
              }}
            />

            <button
              className={styles.btn}
              style={{ marginTop: "1rem" }}
              onClick={() => handleDiscount()}
            >
              Apply
            </button>
          </CheckoutLines>
          <Line type="bottom-line"></Line>
          {/* ---------------- */}

          {/* Payment Details */}
          <Line type="bottom-line">
            <label htmlFor="postalCode" className={styles.labelText}>
              Product Price
            </label>
            {initUsPrice} USD
          </Line>
          <Line type="bottom-line">
            <label htmlFor="postalCode" className={styles.labelText}>
              Shipping
            </label>
            {shippingCost} USD
          </Line>
          <Line type="bottom-line">
            <label className={styles.labelText}>Taxes</label>
            {tax !== "0" ? `${tax} usd` : "0 usd"}
          </Line>
          <Line type="bottom-line">
            <label className={styles.labelText}>duties</label>
            {country === "34-Canada" || country === "212-United States"
              ? "0 usd"
              : "not included"}
          </Line>
          <Line type="bottom-line">
            <label className={styles.labelText}>Total</label>
            {price} USD
          </Line>
          {country !== "34-Canada" && country !== "212-United States" ? (
            <Line type="bottom-line" className={styles.taxBoxForAll}>
              the shipping provider may collect duties, taxes, customs charges,
              and import fees, which is your responsibility to pay.{" "}
            </Line>
          ) : null}
          {/* ---------------- */}
          {/* Payment Information */}
          <Line type="bottom-line"></Line>
          <Line type="bottom-line">payment information</Line>
          <Line type="bottom-line">how do you want to pay</Line>
          <Line type="bottom-line">
            <RadioButton
              checked={paymentType === 1}
              name="payment-type"
              onClick={() => {
                setPaymentType(1);
              }}
            >
              debit or credit card
            </RadioButton>
          </Line>
          <Line type="bottom-line">
            <RadioButton
              checked={paymentType === 2}
              name="payment-type"
              onClick={() => {
                setPaymentType(2);
              }}
            >
              paypal
            </RadioButton>
          </Line>
          <CheckoutLines
            text="Name on Card"
            style={{
              display: paymentType === 1 ? "" : "none",
            }}
          >
            <input
              className={styles.inputText}
              type="text"
              value={cardName}
              style={{
                display: paymentType === 1 ? "" : "none",
              }}
              onChange={(e) => {
                setCardName(e.target.value);
              }}
            />
          </CheckoutLines>
          <Text
            style={{
              display: paymentType === 1 ? "" : "none",
              paddingBottom: "0.5px",
            }}
          >
            number on card
          </Text>
          <Line
            type="bottom-line"
            text="CVC"
            className={styles.atCenter}
            style={{
              display: paymentType === 1 ? "" : "none",
              paddingBottom: "0.5px",
            }}
          >
            <CardNumber />
          </Line>
          <Text
            style={{
              display: paymentType === 1 ? "" : "none",
              paddingBottom: "0.5px",
            }}
          >
            security code – cvc or cvv or ccv
          </Text>
          <Line
            type="bottom-line"
            text="CVC"
            className={styles.atCenter}
            style={{ display: paymentType === 1 ? "" : "none" }}
          >
            <CardSection />
          </Line>
          <Text
            style={{
              display: paymentType === 1 ? "" : "none",
              paddingBottom: "0.5px",
            }}
          >
            Expiry date
          </Text>
          <Line
            type="bottom-line"
            text="CVC"
            className={styles.atCenter}
            style={{ display: paymentType === 1 ? "" : "none" }}
          >
            <CardCvC />
          </Line>
          <Line
            type="bottom-line"
            style={{ display: paymentType === 2 ? "" : "none" }}
          ></Line>
          <Line type="bottom-line">billing address</Line>
          <Line type="bottom-line">
            the address that matches your payment method
          </Line>
          <Line type="bottom-line">
            <RadioButton
              checked={!hasBilling}
              name="billing-address"
              onClick={() => {
                setHasBilling(false);
              }}
            >
              same as shipping address
            </RadioButton>
          </Line>
          <Line type="bottom-line">
            <RadioButton
              checked={hasBilling}
              name="billing-address"
              onClick={() => {
                setHasBilling(true);
              }}
            >
              use a different billing address
            </RadioButton>
          </Line>
          <div style={{ display: hasBilling ? "" : "none" }}>
            <CheckoutLines text="Country">
              <select
                className={styles.selectorForPhone}
                value={countryBilling}
                style={{ textTransform: "lowercase" }}
                onChange={(e) => {
                  setCountryStateBilling("");
                  setCountryBilling(e.target.value);
                }}
              >
                <Countries />
              </select>
            </CheckoutLines>
            <CheckoutLines text="Full Name">
              <input
                className={styles.inputText}
                type="text"
                value={nameBilling}
                onChange={(e) => {
                  setNameBilling(e.target.value);
                }}
              />
            </CheckoutLines>
            <CheckoutLines text="Phone Number">
              <input
                className={styles.inputText}
                type="text"
                value={phoneBilling}
                onChange={(e) => {
                  setPhoneBilling(e.target.value);
                }}
              />
            </CheckoutLines>
            <CheckoutLines text="Email address">
              <input
                className={styles.inputText}
                type="text"
                value={emailBilling}
                onChange={(e) => {
                  setEmailBilling(e.target.value);
                }}
              />
            </CheckoutLines>
            <CheckoutLines text="address line 1">
              <input
                className={styles.inputText}
                type="text"
                value={addressBilling}
                onChange={(e) => {
                  setAddressBilling(e.target.value);
                }}
              />
            </CheckoutLines>
            <CheckoutLines text="address line 2 (optional)">
              <input
                className={styles.inputText}
                type="text"
                value={address1Billing}
                onChange={(e) => {
                  setAddress1Billing(e.target.value);
                }}
              />
            </CheckoutLines>
            <CheckoutLines text="address line 3 (optional)">
              <input
                className={styles.inputText}
                type="text"
                value={address2Billing}
                onChange={(e) => {
                  setAddress2Billing(e.target.value);
                }}
              />
            </CheckoutLines>
            <CheckoutLines text="apartment, suite, floor, unit (optional)">
              <input
                className={styles.inputText}
                type="text"
                value={unitBilling}
                onChange={(e) => {
                  setUnitBilling(e.target.value);
                }}
              />
            </CheckoutLines>
            <CheckoutLines
              text="Province"
              style={{
                display: countryBilling === "34-Canada" ? "block" : "none",
              }}
            >
              <select
                className={styles.selectorForPhone}
                value={countryStateBilling}
                onChange={(e) => {
                  setCountryStateBilling(e.target.value);
                }}
                style={{ textTransform: "lowercase" }}
              >
                <CanadaProvinces />
              </select>
            </CheckoutLines>
            <CheckoutLines
              text="State"
              style={{
                display:
                  countryBilling === "212-United States" ? "block" : "none",
              }}
            >
              <select
                className={styles.selectorForPhone}
                value={countryStateBilling}
                onChange={(e) => {
                  setCountryStateBilling(e.target.value);
                }}
                style={{ textTransform: "lowercase" }}
              >
                <AmericanStates />
              </select>
            </CheckoutLines>
            <CheckoutLines text="city">
              <input
                className={styles.inputText}
                type="text"
                value={cityBilling}
                onChange={(e) => {
                  setCityBilling(e.target.value);
                }}
              />
            </CheckoutLines>
            <CheckoutLines
              text={
                countryBilling === "212-United States"
                  ? "zip code"
                  : "Postal Code"
              }
            >
              <input
                className={styles.inputText}
                type="text"
                value={postalCodeBilling}
                onChange={(e) => {
                  setPostalCodeBilling(e.target.value);
                }}
              />
            </CheckoutLines>
          </div>
          <PolicyLine
            content={paymentType === 2 ? "paypal " : "place order"}
            clickTerms={() => {
              setShowTerms(true);
            }}
            clickPolicy={() => {
              setShowPolicy(true);
            }}
          />
          <Line type="bottom-line">{errormessage}</Line>
          <Line
            type="bottom-line"
            style={{ display: paymentType === 2 ? "" : "none" }}
          >
            <div>
              <PayPalCheckoutButton
                price={price}
                description={productName}
                checkValidation={validate}
                handleCreateOrder={handleCreateOrder}
                setPending={setPending}
                pid={props.productId}
                setErrormessage={setErrormessage}
              />
            </div>
          </Line>
          <Line
            type="bottom-line"
            style={{ display: paymentType === 1 ? "" : "none" }}
          >
            <button
              className={styles.btn}
              onClick={() => {
                if (validate()) {
                  handleSubmit();
                }
              }}
              disabled={!stripe || pending}
            >
              place order
            </button>
          </Line>
          <Line type="empty-line" />
        </div>
      </div>

      <PrivacyPolicy
        show={showPolicy}
        closeFun={() => {
          setShowPolicy(false);
        }}
      />
      <TermsAndConditions
        show={showTerms}
        closeFun={() => {
          setShowTerms(false);
        }}
      />
    </>
  );
}
