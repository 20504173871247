import React, { useEffect, useState } from "react";
import axios from "../../axios-backend";
import { canadianTaxes } from "../../utils/canadianTaxes";
import BackButton from "../main/BackButton";
import Line from "../main/Line";

export default function OrderDetails(props) {
  const [order, setOrder] = useState();

  useEffect(() => {
    const id = props.match.params.id;
    axios.get(`/orders/${id}`).then((p) => {
      if (p) {
        // console.log(p);
        setOrder(p.data);
      }
    });
  }, []);
  return (
    <div style={{ height: "100%" }}>
      <BackButton />
      {order ? (
        <>
          <Line type="bottom-line">
            <span>Product name: {order.productName}</span>
          </Line>

          <Line type="bottom-line">
            <span>Collection name: {order.typeName}</span>
          </Line>

          <Line type="bottom-line">
            <span>Product Card number: {order.cardNumber}</span>
          </Line>
          <Line type="bottom-line">
            <span>size: {order.size}</span>
          </Line>
          <Line type="bottom-line">
            <span>
              gender:{" "}
              {order.gender == 2
                ? "unisex"
                : order.gender == 1
                ? "men"
                : "women"}
            </span>
          </Line>
          <Line type="bottom-line">
            <span>Color: {order.color.join(" ")}</span>
          </Line>

          <Line type="bottom-line">
            <span>actual product price: $ {order.productPrice}</span>
          </Line>

          <Line type="bottom-line">
            <span>
              discount code: {order.discountAmount === 0 ? "discount not applied" : order.discountCode}
            </span>
          </Line>
          <Line type="bottom-line">
            <span>
              discount: {order.discountAmount}{" "}%
            </span>
          </Line>
          <Line type="bottom-line">
            <span>
              product price (after discount): ${" "}
              {order.productPriceAfterDiscount}
            </span>
          </Line>

          <Line type="bottom-line">
            <span>shipping price: $ {order.shippingFee}</span>
          </Line>

          {/* canada tax */}
          {order.country == "Canada" && (
            <>
              <Line type="bottom-line">
                <span>
                  gst:$
                  {(
                    (order.productPriceAfterDiscount *
                      canadianTaxes[order.state].gst) /
                    100
                  ).toFixed(2)}
                </span>
              </Line>
              <Line type="bottom-line">
                <span>
                  pst:$
                  {(
                    (order.productPriceAfterDiscount *
                      canadianTaxes[order.state].pst) /
                    100
                  ).toFixed(2)}
                </span>
              </Line>
              <Line type="bottom-line">
                <span>
                  qst:$
                  {(
                    (order.productPriceAfterDiscount *
                      canadianTaxes[order.state].qst) /
                    100
                  ).toFixed(2)}
                </span>
              </Line>
              <Line type="bottom-line">
                <span>
                  hst:$
                  {(
                    (order.productPriceAfterDiscount *
                      canadianTaxes[order.state].hst) /
                    100
                  ).toFixed(2)}
                </span>
              </Line>
              <Line type="bottom-line">
                <span>
                  total tax: ${" "}
                  {(
                    (order.productPriceAfterDiscount *
                      canadianTaxes[order.state].total) /
                    100
                  ).toFixed(2)}
                </span>
              </Line>
            </>
          )}

          <Line type="bottom-line">
            <span>duties: $ {order.duty}</span>
          </Line>

          <Line type="bottom-line">
            <span>final Price: $ {order.finalPrice}</span>
          </Line>
          <Line type="bottom-line">
            <span>payment ID: {order.checkoutCode}</span>
          </Line>
          <Line type="bottom-line">
            <span>name: {order.name}</span>
          </Line>
          <Line type="bottom-line">
            <span>email: {order.email}</span>
          </Line>
          <Line type="bottom-line">
            <span>phone: {order.phone}</span>
          </Line>
          <Line type="bottom-line">
            <span>country: {order.country}</span>
          </Line>
          <Line type="bottom-line">
            <span>state: {order.state}</span>
          </Line>
          <Line type="bottom-line">
            <span>city: {order.city}</span>
          </Line>
          <Line type="bottom-line">
            <span>unit: {order.unit}</span>
          </Line>
          <Line type="bottom-line">
            <span>address line 1: {order.address}</span>
          </Line>
          <Line type="bottom-line">
            <span>address line 2: {order.address1}</span>
          </Line>
          <Line type="bottom-line">
            <span>address line 3: {order.address2}</span>
          </Line>
          <Line type="bottom-line">
            <span>postalCode: {order.postalCode}</span>
          </Line>
          <Line type="bottom-line">
            <span>card Name: {order.cardName}</span>
          </Line>
          <Line type="bottom-line">
            <span>
              checkout time:{" "}
              {new Date(order.checkoutTime).toLocaleString("en-US", {
                timeZone: "America/Vancouver",
              })}
            </span>
          </Line>
          {order.hasBilling ? (
            <>
              <Line type="bottom-line"></Line>
              <Line type="bottom-line">billing information</Line>
              <Line type="bottom-line">
                <span>name: {order.nameBilling}</span>
              </Line>
              <Line type="bottom-line">
                <span>email: {order.emailBilling}</span>
              </Line>
              <Line type="bottom-line">
                <span>phone: {order.phoneBilling}</span>
              </Line>
              <Line type="bottom-line">
                <span>country: {order.countryBilling}</span>
              </Line>
              <Line type="bottom-line">
                <span>state: {order.stateBilling}</span>
              </Line>
              <Line type="bottom-line">
                <span>city: {order.cityBilling}</span>
              </Line>
              <Line type="bottom-line">
                <span>unit: {order.unitBilling}</span>
              </Line>
              <Line type="bottom-line">
                <span>address line 1: {order.addressBilling}</span>
              </Line>
              <Line type="bottom-line">
                <span>address line 2: {order.address1Billing}</span>
              </Line>
              <Line type="bottom-line">
                <span>address line 3: {order.address2Billing}</span>
              </Line>
              <Line type="bottom-line">
                <span>postalCode: {order.postalCodeBilling}</span>
              </Line>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
}
